import React from "react";
import ReactPaginate from "react-paginate";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { portoPictures } from "@utils/static-data";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import portoStyles from "@cssmodules/portfolio.module.scss";

export default function Portfolio() {
    const images = useStaticQuery(graphql`
        query PortoQuery {
            polo: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
        }
    `);

    const [activePages, setActivePages] = React.useState(0);
    const renderPorto = () => {
        const firstIndex = activePages * 16;
        const porto = [...images.polo.edges].slice(firstIndex, firstIndex + 16);

        const view = porto.map(({ node }, key) => (
            <Col className={portoStyles.col} xs={6} md={6} lg={3} key={key}>
                <div className={portoStyles.card}>
                    <Img
                        fluid={node.childImageSharp.fluid}
                        className="h-100"
                        alt={node.base.split(".")[0]}
                    />
                </div>
            </Col>
        ));

        return view;
    };

    const handlePageClick = data => {
        setActivePages(data.selected);
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <main className={`container ${portoStyles.container}`}>
                <SEO title="Portfolio" />
                <Row>{renderPorto()}</Row>
                <ReactPaginate
                    previousLabel={null}
                    nextLabel={null}
                    breakLabel={"..."}
                    breakClassName={portoStyles.break}
                    pageCount={Math.ceil(portoPictures.length / 16)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={portoStyles.pagination}
                    pageClassName={portoStyles.paginationSub}
                    activeClassName={portoStyles.active}
                />
            </main>
        </Layout>
    );
}
